/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The subject line is the first thing your customer will see when you send a review request email."), "\n", React.createElement(_components.p, null, "That makes it the first opportunity to miss out on a review. Needless to say the subject you use is very important."), "\n", React.createElement(_components.p, null, "Let's look at some examples."), "\n", React.createElement(_components.h2, {
    id: "to-the-point",
    style: {
      position: "relative"
    }
  }, "To The Point", React.createElement(_components.a, {
    href: "#to-the-point",
    "aria-label": "to the point permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When sending a review request email it is best to reference or at least start building toward your upcoming request in the subject."), "\n", React.createElement(_components.p, null, "No one likes a bait and switch."), "\n", React.createElement(_components.p, null, "You will irritate your customer if you use an unrelated subject to get them to open the email and surprise them with something else."), "\n", React.createElement(_components.h4, {
    id: "examples",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples",
    "aria-label": "examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{company_name} has sent you a satisfaction survey\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{company_name} would like your feedback\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{company_name} has sent you a review invitation\n")), "\n", React.createElement(_components.h2, {
    id: "make-it-personal",
    style: {
      position: "relative"
    }
  }, "Make it Personal", React.createElement(_components.a, {
    href: "#make-it-personal",
    "aria-label": "make it personal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Another trick is to include the recipients name or your company name, something that is familiar to the customer."), "\n", React.createElement(_components.p, null, "It will help them trust the authenticity of the emails, but never use their full name. That just screams \"sent by a robot\" and does more harm than good."), "\n", React.createElement(_components.h4, {
    id: "examples-1",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples-1",
    "aria-label": "examples 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{customer_name}, thank you for choosing {company_name}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{customer_name}, will you do {company_name} a favour?\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{customer_name}, {company_name} has sent you a review request \n")), "\n", React.createElement(_components.h2, {
    id: "stand-out",
    style: {
      position: "relative"
    }
  }, "Stand Out", React.createElement(_components.a, {
    href: "#stand-out",
    "aria-label": "stand out permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "There are a couple things you can try to get your email to stand out from the rest of the inbox."), "\n", React.createElement(_components.p, null, "One is Emojis, many people say that Emojis in the subject line lead to a higher email open rate."), "\n", React.createElement(_components.h4, {
    id: "examples-2",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples-2",
    "aria-label": "examples 2 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{company_name} would like to hear your feedback 👂\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "⏰ Don't forget to post a review for {company_name}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "How did we do? ⭐️ or ⭐️⭐️⭐️⭐️⭐️\n")), "\n", React.createElement(_components.p, null, "Your other option is to make the subject attention grabbing, but this can cause some customers to think the email is spam. Be careful with this one. You will be walking a fine line."), "\n", React.createElement(_components.h4, {
    id: "examples-3",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples-3",
    "aria-label": "examples 3 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{customer_name}, will you be radically honest with us?\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "{customer_name}, we need your help\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
